import React, { Component } from 'react'
import { Link } from 'gatsby'

import Wordmark from '../assets/images/wordmark.svg'
import LogoIcon from '../assets/images/logo-icon.svg'
import { Close, Facebook, LinkedIn, Instagram } from './icons.js'



class Header extends Component {

  state = {
    offCanvas: false,
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  _scrollTo = (element) => {
    document.querySelector(element).scrollIntoView({ 
      behavior: 'smooth' 
    })
    this._hideOffCanvas()
  }

  render() {

    let { offCanvas } = this.state

    let headerClass = 'header'

    return (

      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <Link to='/' className='header__logo' title="Paradise Physio">
              <img className='icon' src={LogoIcon} alt="Paradise Physio" />
              <img className='wordmark' src={Wordmark} alt="Paradise Physio" />
            </Link>
            <div className='header__contact'>
            	<a className="btn btn--blue" href="tel:+61753735433"><span className='mobile'>Call</span><span className='desktop'>Call Us</span></a>
              <a href="https://paradise-physio.au3.cliniko.com/bookings" title="Book medical appointments with James de Leijer" rel="noopener noreferrer" target="_blank" className="btn"><span className='mobile'>Book</span><span className='desktop'>Make a Booking</span></a>
              <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
                <span className='lines'></span>
              </button>
            </div>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'off-canvas--active' }`}>
          <div className='off-canvas__inner'>
            <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
              <span className='lines'></span>
            </button>
            <nav className='off-canvas__nav'>
              <ul>
                <li><span onClick={() => this._scrollTo('.lead-form')}>About</span></li>
                <li><span onClick={() => this._scrollTo('.timeline')}>Our Physios</span></li>
                <li><span onClick={() => this._scrollTo('.pricing')}>Prices</span></li>
                <li><span onClick={() => this._scrollTo('.items')}>What we treat</span></li>
                <li><span onClick={() => this._scrollTo('.partners')}>Associations</span></li>
                <li><span onClick={() => this._scrollTo('.more-info')}>More Information</span></li>
              </ul>
            </nav>
            <div className='off-canvas__address'>
              <p>Paradise Physio and Rehab<br />
              Lerner St,<br />
              Pacific Paradise QLD 4564</p>
              <p><a href='tel:+61753735433' target='_blank' rel='noopener norefferer'>(07) 5373 5433</a></p>
            </div>
            <ul class="off-canvas__social">
              <li><a href='https://www.facebook.com/ParadisePhysioAu' target='_blank' rel='noopener noreferrer'><Facebook color={'#FFF'} /></a></li>
              <li><a href='https://www.instagram.com/ParadisePhysioAu' target='_blank' rel='noopener noreferrer'><Instagram color={'#FFF'} /></a></li>
              <li><a href='https://www.linkedin.com/in/james-de-leijer-7b32617b/' target='_blank' rel='noopener noreferrer'><LinkedIn color={'#FFF'} /></a></li>
            </ul>
          </div>
        </div>
      </>

    )

  }

}

export default Header
