import React from 'react'

import Logo from '../assets/images/logo.svg'

const Footer = () => (
  <>
    <footer className='footer'>
      <div className='footer__inner'>
        <div className='footer__col'>
          <img src={Logo} alt='Paradise Physio' />
        </div>
        <div className='footer__col'>
          <h4>Paradise Physio</h4>
          <p>Lerner St,<br />
          Pacific Paradise QLD 4564</p>
        </div>
        <div className='footer__col'>
          <h4>Contact</h4>
          <ul>
            <li><a href='tel:+61753735433'>(07) 5373 5433</a></li>
          </ul>
        </div>
        <div className='footer__col'>
          <a href="https://paradise-physio.au3.cliniko.com/bookings" title="Book medical appointments with James de Leijer" target="_blank" rel="noopener noreferrer" className="btn">Make a Booking</a>
        </div>
        <p className='footer__credits'><a href='https://design-digital.com.au' target='_blank' rel='noopener noreferrer'>Website by Design Digital</a></p>
      </div>
    </footer>
  </>
)

export default Footer
